import * as React from "react"
import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

import Meta from '../../components/Meta';
import Layout from '../../components/Layout';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Content = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-items: left;
  margin: 2rem 2rem 6rem 2rem;

  ${Media.greaterThan('large')`
    margin: 9rem auto;
    max-width: 100rem;
  `}
`;

const Title = Styled.h1`
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1;

  ${Media.greaterThan('large')`
    font-size: 5.2rem;
  `}
`;

const SectionTitle = Styled.h3`
  font-size: 2.6rem;
  margin-top: 6rem;

  ${Media.greaterThan('large')`
    font-size: 3.2rem;
    margin-top: 9rem;
  `}
`;

const Text = Styled.p`
  font-size: 2rem;
  line-height: 1.2;
  text-align: justify;

  ${Media.greaterThan('large')`
    font-size: 2.4rem;
  `}
`;

const Link = Styled.a`
  color: #1e90ff !important;
`;

const Row = Styled.div`
  display: block;

  ${Media.greaterThan('large')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const Column = Styled.div`
  width: 100%;

  ${Media.greaterThan('large')`
    width: calc((100% - 10rem) / 2);
  `}
`;


const About = ({ data }) => (
    <Layout>
      <Meta
        title="Theme Generator"
        description="Instantly generate themes and extract design tokens from any source with Theme Generator"
      />
      <Navbar />
      <Content>
        <Title>About Theme Generator</Title>
        <Text>
          Theme Generator is an online theme generator that lets you easily create a theme from any source.
          When you upload an image or a website URL, Theme Generator uses <Link href="https://uizard.io/design-assistant" target="_blank">Uizard</Link>'s powerful AI to extract components and styling properties from your content.
          You can then use any of these generated colors, typographies, style, and components for designing your next app or website.
        </Text>

        <Row>
          <Column>
            <SectionTitle>Turn any source of inspiration into a design system <span role="img" aria-label="Camera Flash">📸</span></SectionTitle>

            <Text>
              Want to create a website that matches your favorite brand? Or perhaps an app inspired by that picture you saw on Pinterest? 
              <br/><br/>
              Theme Generator can turn any source of inspiration into a set of design components that you can use for your next project.

            </Text>
          </Column>

          <Column>
            <SectionTitle>Extract design tokens from websites <span role="img" aria-label="Paint Palette">🎨</span></SectionTitle>

            <Text>
              Most companies already have a design system: it is embedded in their website!
              <br/><br/>
              Theme Generator can extract design tokens from any website and turn them into a customizable and editable theme.
              Weither you want to extract design assets from your home page, past shipped projects, or favorite brands.
            </Text>
          </Column>
        </Row>
        <div>
          <SectionTitle>Pick up where you left off <span role="img" aria-label="Hammer and Wrench">🛠</span></SectionTitle>
          <Text>
            Theme Generator integrates with <Link href="https://uizard.io/design-assistant" target="_blank">Uizard</Link>, the easiest design tool for creating digital products.
            After using the online theme generator to create your unique UI theme, you can seamlessly use your generated theme to design a complete product experience in <Link href="https://uizard.io/design-assistant" target="_blank">Uizard</Link>.
          </Text>
        </div>
      </Content>
      <Footer />
    </Layout>
  )

  export default About;

